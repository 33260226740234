<script>
    import animationData from "@/components/widgets/hzomhqxz.json";
    import Lottie from "@/components/widgets/lottie.vue";
    import serverConfig from '@/helpers/config.js'

    export default {
        setup() {
            const backgroundImg = `url('${serverConfig.EP}public/assets/img/background-logo.png')`
            return { backgroundImg };
        },
        data() {
            return {
                config:serverConfig,
                defaultOptions: {
                    animationData: animationData
                },
            }
        },
        mounted(){
            document.getElementById('auth-particles').style.backgroundImage = this.backgroundImg;
        },
        components: {
            lottie: Lottie
        }
    }
</script>

<template>
    <div class="auth-page-wrapper pt-5">
        <!-- auth page bg -->
        <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
            <div class="bg-overlay"></div>
        </div>

        <!-- auth page content -->
        <div class="auth-page-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center mt-sm-5 mb-4 text-white-50">
                            <div>
                                <router-link to="/" class="d-inline-block auth-logo">
                                    <img :src="config.EP+'/public/assets/img/logo-light.png'" alt="" height="30" />
                                </router-link>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <!-- end row -->

                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-5">
                        <div class="card mt-4">
                            <div class="card-body p-4 text-center">
                                <lottie colors="primary:#4b38b3,secondary:#08a88a" :options="defaultOptions"
                                    :height="180" :width="180" />
                                <div class="mt-4 pt-2">
                                    <h5>Sei disconnesso</h5>
                                   
                                    <div class="mt-4">
                                        <router-link to="/login" class="btn btn-success w-100">Accedi
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <!-- end card body -->
                        </div>
                        <!-- end card -->

                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </div>
        <!-- end auth page content -->

        <!-- footer -->
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center">
                            <p class="mb-0 text-muted">
                              &copy; {{new Date().getFullYear()}} 
                              <a href="https://www.neuralika.com" target="blank">Neuralika</a> 
                              </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- end Footer -->
    </div>
    <!-- end auth-page-wrapper -->
</template>